<template>
  <div class="course_video_page">
    <div class="video_block">
      <videoPlayer
        v-if="$route.query.normalUrl"
        :videoid="$route.query.normalUrl"
        :isFree ="isFreePlay"
      />
    </div>
    <div class="class_name">{{ $route.query.videoname }}</div>
    <div class="content" v-show="isShow && !isFreePlay">
      <div class="right">
        <div class="top">
          <div
            class="tab_list_one tab"
            :class="{ tab_list_active: tabListActive === 'desc' }"
            @click="tabListActive = 'desc'"
          >
            课程简介
          </div>
          <div
            class="tab_list_two tab"
            v-show="$store.state.userInfo.teacherId"
            :class="{ tab_list_active: tabListActive === 'plan' }"
            @click="tabListActive = 'plan'"
          >
            课程教案
          </div>
          <div
            class="tab_list_three tab"
            :class="{ tab_list_active: tabListActive === 'notes' }"
            @click="tabListActive = 'notes'"
          >
            笔记
          </div>
        </div>
        <div class="bk"></div>
        <div
          class="tab_list_one_desc"
          v-show="tabListActive === 'desc'"
          v-html="$route.query.videoinfo"
        ></div>
        <div class="plan_block" v-show="tabListActive === 'plan'">
          <div class="item" v-for="(item, index) in pdfList" :key="index">
            <div class="down_left">
              <span class="pdf">pdf</span>
              <span class="pdf_title">{{ item.title }}</span>
            </div>
            <a :href="constant.URL + item.url"
              ><div class="btn">在线浏览</div></a
            >
          </div>
        </div>
        <div class="tab_list_three_block" v-show="tabListActive === 'notes'">
          <div class="submit">
            <textarea
              v-model="notesValue"
              maxlength="100"
              cols="30"
              rows="10"
            ></textarea>
            <div class="btn tab" @click="notsSubmit">提交</div>
          </div>
          <div class="notes_list">
            <div
              class="notes_list_block"
              v-for="item in notsList"
              :key="item.id"
            >
              <div class="notes_list_block_desc">{{ item.contents }}</div>
              <div class="notes_list_block_time">
                {{ common.dateFormat(item.createtime * 1000, "yyyy-MM-dd") }}
              </div>
              <div class="remove tab" @click="remove(item)"></div>
            </div>
          </div>
          <comPagination
            :params="params"
            @changePage="changePage"
          ></comPagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videoPlayer from "@/components/video.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "courseVideo",
  components: {
    videoPlayer,
    comPagination,
  },
  data() {
    return {
      tabIndex: 0,
      tabListActive: "desc",
      notesValue: "",
      videoId: "",
      params: {
        total: 0,
        page: 0,
        size: 10,
      },
      notsList: [],
      pdfList: [],
      option: {},
      isShow: "", //一键上课页面跳转过来不显示课程教案/笔记。。。
      isFreePlay:false,
    };
  },
  created() {
    this.isFreePlay = this.$route.query.isFree == 'true' ? true : false;
  },
  mounted() {
    console.log(this.isFreePlay,'this.isFree')
    if(!this.isFreePlay){
      let pdf =
        this.$route.query.attachment && this.$route.query.attachment.split(",");
      this.pdfList = [];
      pdf.forEach((item) => {
        this.pdfList.push({
          title: this.$route.query.videoname,
          url: item,
        });
      });
    }
    
    this.getNotesList();
    
  },
  methods: {
    getNotesList() {
      let data = {
        videoId: this.$route.query.id,
        userId: this.$store.state.userInfo.userId,
      };
      this.api.course.findNotsByVideoId(this.params, data).then((res) => {
        this.params.total = res.data.total;
        this.notsList = res.data.list;
      });
    },
    changePage(val) {
      this.params.page = val;
      this.getNotesList();
    },
    // 笔记提交
    notsSubmit() {
      if(!this.notesValue.trim()){
        this.$notice({
          message: '请添加笔记'
        }).isShow()
        return;
      }
      let data = {
        videoId: this.$route.query.id,
        userId: this.$store.state.userInfo.userId,
        contents: this.notesValue,
      };
      this.api.course.add(data).then((res) => {
        if (res.flag) {
           this.$notice({
                type: 'success',
                message: '添加笔记成功'
              }).isShow()
          this.notesValue = "";
          this.getNotesList();
        }
      });
    },
    // 删除笔记
    remove(item) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.api.course.notsDelete(item.id).then((res) => {
            if (res.flag) {
              this.$notice({
                    type: 'success',
                    message:'删除笔记成功'
                  }).isShow()
              this.getNotesList();
            }
          });
        })
        .catch(() => {
          return;
        });
      
    },
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      if (from.path === "/attendClass") {
        vm.isShow = false;
      } else {
        vm.isShow = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.course_video_page {
  width: 100%;
  .video_block {
    height: 714px;
    background: #228ded;
    padding: 0 315px;
    margin-bottom: 70px;
    margin-top: 40px;
    box-sizing: border-box;
    width: 100%;
  }
  .class_name {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 30px;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    @include flex(row, center, flex-start);
    .left {
      width: 320px;
      margin-right: 60px;
      margin-bottom: 80px;
      font-size: 18px;
      .title {
        background: #cc1a30;
        color: #fff;
        font-size: 28px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-weight: bold;
      }
      .text {
        height: auto;
        line-height: 40px;
        padding: 15px 0;
        font-size: 24px;
        font-weight: 700;
        display: block;
        text-align: center;
        background: #db606f;
        color: #fff;
        border-bottom: solid 1px #fff;
        letter-spacing: 5px;
        position: relative;
      }
      .text:nth-child(2n-1) {
        background: #e58c97;
      }
    }
    .right {
      width: 80%;
      margin: 0 auto 100px;
      box-sizing: border-box;
      .top {
        @include flex(row, flex-start, center);
        .tab_list_one {
          line-height: 80px;
          font-size: 24px;
          font-weight: 700;
          background: #e58d98;
          color: #fff;
          padding-left: 120px;
          padding-right: 150px;
          border-top-right-radius: 75px;
          z-index: 333;
        }
        .tab_list_two,
        .tab_list_three {
          line-height: 80px;
          font-size: 24px;
          font-weight: 700;
          background: #e58d98;
          color: #fff;
          margin-left: -70px;
          padding-left: 120px;
          padding-right: 70px;
          z-index: 222;
          border-top-right-radius: 0.75rem;
        }
        .tab_list_three {
          z-index: 111;
        }
        .tab_list_active {
          background: #cb1b31;
        }
      }
      .tab_list_three_block {
        padding-right: 50px;
        .submit {
          @include flex(column, flex-start, flex-end);
          padding: 20px 0;
          textarea {
            height: 200px;
            background: #f5d1d6;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            font-size: 18px;
            line-height: 24px;
            border: 0;
          }
          .btn {
            background: #cb1b31;
            color: #fff;
            font-size: 30px;
            font-weight: 700;
            border: none;
            width: 330px;
            height: 54px;
            box-shadow: none;
            line-height: 54px;
            margin-top: 20px;
            text-align: center;
          }
        }
      }
      .notes_list {
        width: 100%;
        margin-top: 50px;
        border-top: solid 3px #cb1b31;
        font-size: 16px;
        line-height: 20px;
        background: #f5d1d6;
        .notes_list_block {
          width: 100%;
          padding: 15px 60px 15px 20px;
          border-bottom: solid 1px #cb1b31;
          width: 100%;
          box-sizing: border-box;
          position: relative;
          text-align: left;
          .notes_list_block_time {
            color: #999;
            font-size: 16px;
            line-height: 16px;
            margin-top: 10px;
          }
          .remove {
            width: 30px;
            height: 30px;
            @include bg("../../assets/img/lesson/del.png");
            cursor: pointer;
            position: absolute;
            top: 25px;
            right: 30px;
          }
        }
      }
      .plan_block {
        width: 100%;
        padding-right: 50px;
        box-sizing: border-box;
        .item {
          @include flex(row, space-between, center);
          border-bottom: solid 1px #cb1b31;
          padding: 20px 0;
          font-size: 20px;
          line-height: 30px;
          color: #cb1b31;
          .down_left {
            span {
              display: inline-block;
            }
            .pdf {
              border: solid 1px #cb1b31;
              width: 90px;
              text-align: center;
              height: 40px;
              line-height: 40px;
              border-radius: 10px;
            }
            .pdf_title {
              padding-left: 25px;
            }
          }
          .btn {
            width: 150px;
            text-align: center;
            height: 40px;
            line-height: 40px;
            border-radius: 50px;
            background: #228ded;
            color: #fff;
            transition: all 0.3s;
          }
          .btn:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .bk {
    width: 100%;
    height: 0.56rem;
    background: #f5d1d6;
  }
  .tab_list_one_desc {
    font-size: 20px;
    line-height: 30px;
    color: #cb1b31;
    padding: 20px 0;
    text-align: left;
  }
}
</style>
